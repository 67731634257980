body {
    font-family: sans-serif;
    margin: 0;
  }
  
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 1rem;
  }
  .waitlist {
    max-width: 900px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    margin-bottom: 1rem;
  }
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
  }
  
  button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    border: 1px solid #007bff;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
  }